import React, { useState, useEffect } from "react";
import { makeStyles, Button, Box, Typography } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import axios from "axios";
import { localeFunction } from "src/utils";
import ButtonCircularProgress from "./ButtonCircularProgress";
const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "transparent",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    // border: "2px solid #000",
    // width: "30%",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  ClassLoation: {
    color: "#ff0000bd",
    fontWeight: "600",
    marginTop: "15px",
  },
  blockButton: {
    background: "#8080805e",
    width: "70%",
    color: "#333",
    marginTop: "12px",
    padding: "10px",
    borderRadius: "50px",
  },
  blockButton1: {
    background: "#017c6b",
    width: "70%",
    color: "#fff",
    marginTop: "12px",
    padding: "10px",
    borderRadius: "50px",
    "&:hover": {
      background: "#017c6b",
      width: "70%",
      color: "#fff",
      marginTop: "12px",
      padding: "10px",
      borderRadius: "50px",
    },
  },
  calis: {
    width: "100%",
  },
  notificationBox: {
    width: "30%",
    "@media(max-width:768px)": {
      width: "80%",
    },
  },
}));

export default function ServerModal({
  setCheckLocation,
  checkLocation,
  getLocation,
  showPosition,
}) {
  const classes = useStyles();
  const rootRef = React.useRef(null);
  const [locAllowopen, setlocAllowOpen] = useState(true);

  const [_isloading, setLoading] = useState(false);

  return (
    <div className={classes.root} ref={rootRef}>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={locAllowopen}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Box className={classes.notificationBox}>
          <div className={classes.paper}>
            <h2
              id="server-modal-title"
              style={{
                color: "#017c6b",
                fontSize: "16px",
                fontWeight: "600",
                breakAll: "break-all",
              }}
            >
             {`${window.location.origin} ${localeFunction("device_allow_8")}`} 
            </h2>
            <p
              id="server-modal-description"
              style={{ color: "black", fontSize: "14px", fontWeight: "600" }}
            >
              {localeFunction("my_Post_text_7")}
            </p>
            <Box
              display={"flex"}
              justifyContent="center"
              className={classes.calis}
            >
              <Button
                className={classes.blockButton1}
                variant="outline"
                onClick={() => {
                  setlocAllowOpen(false);
                }}
              >
                {localeFunction("device_allow_7")}
              </Button>
            </Box>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
