import React, {
  Suspense,
  Fragment,
  useContext,
  useState,
  useEffect,
  useRef,
} from "react";
import { Router, Switch, Route } from "react-router-dom";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { routes } from "src/routes";
import { createBrowserHistory } from "history";
import AuthContext from "src/context/Auth";
import PageLoading from "src/component/PageLoading";
import AuthGuard from "src/component/AuthGuard";
import { ThemeProvider } from "@material-ui/core";
import { createTheme } from "src/theme";
import SettingsContext from "src/context/SettingsContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NitificationPopUp from "src/component/NotificationAlertPopUp";
import { fetchToken, onMessageListener } from "src/apiConfig/service/firebase";
import NotificationAllow from "src/component/NotificationAllow";
import ReactDOM from "react-dom";

import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
const history = createBrowserHistory();

function App() {
  // console.log(theme);
  const [isTokenFound, setTokenFound] = useState(false);
  const [isNotificationErr, setNotificationErr] = useState(false);
  const themeSeeting = useContext(SettingsContext);
  const theme = createTheme({
    theme: themeSeeting.settings.theme,
  });
  const [open, setOpen] = useState(false);
  const [notification, setNotification] = useState();
  const [notificationData, setNotificationData] = useState();
  const myContainer = useRef(null);
  fetchToken(setTokenFound, themeSeeting.handleFireToken, setNotificationErr);
  onMessageListener()
    .then((payload) => {
      setOpen(true);
      setNotification(payload.notification);
      setNotificationData(payload.data);
      // toast.success(payload.notification.title);
    })
    .catch((err) => console.log("failed: ", err));
  useEffect(() => {
    if (isNotificationErr) {
      ReactDOM.render(
        <>
          <NotificationAllow />
        </>,
        document.getElementById("rootid")
      );
    }
  }, [isNotificationErr]);

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <AuthContext>
            <ToastContainer />
            <Router history={history}>
              <div ref={myContainer} id="rootid"></div>
              <NitificationPopUp
                data={notificationData}
                notification={notification}
                open={open}
                setOpen={setOpen}
              />
              <RenderRoutes data={routes} stripePromise={stripePromise} />
            </Router>
          </AuthContext>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;

function RenderRoutes(props) {
  return (
    <Suspense fallback={<PageLoading />}>
      <Switch>
        {props.data.map((route, i) => {
          const Component = route.component;
          const Guard = route.guard ? AuthGuard : Fragment;
          const Layout = route.layout || Fragment;
          return (
            <Route
              exact={route.exact}
              key={i}
              path={route.path}
              render={(routeProps) => (
                <Guard>
                  <Layout>
                    {route.routes ? (
                      <RenderRoutes
                        data={route.routes}
                      />
                    ) : (
                      // Check if the current route is "/plans"
                      route.path === "/plans" ? (
                        <Elements stripe={props.stripePromise}>
                          <Component {...routeProps} />
                        </Elements>
                      ) : (
                        <Component {...routeProps} />
                      )
                    )}
                  </Layout>
                </Guard>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  );
}
