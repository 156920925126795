import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "src/scss/main.css";
import { SettingsProvider } from "src/context/SettingsContext";
import { Provider } from "react-redux";
import { store } from "./Redux/store";
ReactDOM.render(
  <React.StrictMode>
    <SettingsProvider>
      <Provider store={store}>
            <App />
      </Provider>
    </SettingsProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
serviceWorker.unregister();
