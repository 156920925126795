import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage, isSupported } from "firebase/messaging";

var firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDERID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID,
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = (async () => {
  try {
    const isSupportedBrowser = await isSupported();
    if (isSupportedBrowser) {
      return getMessaging(firebaseApp);
    }
    console.log('Firebase not supported this browser');
    return null;
  } catch (err) {
    console.log(err);
    return null;
  }
})();

export const fetchToken = async (setTokenFound, setToken,setNotificationErr) => {
  try {
    const messagingResolve = await messaging;
    const currentToken = await getToken(messagingResolve, {
      vapidKey: process.env.REACT_APP_VAPID_KEY,
    });
    if (currentToken) {
      if (currentToken) {
        console.log('current token for client: ', currentToken);
        setToken(currentToken)
        setTokenFound(true);
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
      } else {
        console.log('No registration token available. Request permission to generate one.');
        setTokenFound(false);
        setNotificationErr(true)
        // shows on the UI that permission is required 
      }
    }
  } catch (err) {
    console.log('An error occurred while retrieving token. ', err);
    setNotificationErr(true)
  }
};

export const onMessageListener = async () =>
  new Promise((resolve) =>
    (async () => {
      const messagingResolve = await messaging;
      onMessage(messagingResolve, (payload) => {
        // console.log('On message: ', messaging, payload);
        resolve(payload);
      });
    })()
  );