export const socketNotification = process.env.REACT_APP_SOCKET_URL;
export const baseurl = process.env.REACT_APP_BASE_URL;
export const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_PLACES_KEY;
export const agoraAppId = process.env.REACT_APP_AGORA_APP_ID;
export const websiteUrl = process.env.REACT_APP_WEBSITE_URL;

let user = `${baseurl}/api/v1/user`;
let product = `${baseurl}/api/v1/product`;
let category = `${baseurl}/api/v1/category`;
let service = `${baseurl}/api/v1/service`;
let event = `${baseurl}/api/v1/event`;
let pet = `${baseurl}/api/v1/pet`;
let post = `${baseurl}/api/v1/post`;
let socket = `${baseurl}/api/v1/socket`;
let uploadMultipleFile = `${baseurl}/api/v1/post`;
let admin = `${baseurl}/api/v1/admin`;
let statics = `${baseurl}/api/v1/static`;

const Apiconfigs = {
  profile: `${user}/profile`,
  signup: `${user}/signup`,
  verifyOTP: `${user}/verifyOTP`,
  resendOTP: `${user}/resendOTP`,
  login: `${user}/login`,
  agoraTokenGenerator: `${user}/agoraTokenGenerator`,
  googlePlaces: `${user}/googlePlaces`,
  notificationList: `${user}/notificationList`,
  sharePost: `${user}/sharePost`,
  callUser: `${user}/callUser`,
  editProfile: `${user}/editProfile`,
  changePassword: `${user}/changePassword`,
  forgotPassword: `${user}/forgotPassword`,
  vendorDashboard: `${user}/vendorDashboard`,
  redeemPointsList: `${user}/redeemPointsList`,
  getAllCountry: `${user}/getAllCountry`,
  getAllCity: `${user}/getAllCity`,
  getAllState: `${user}/getAllState`,
  followUnfollowUser: `${user}/followUnfollowUser/`,
  followerUserList: `${user}/followerUserList`,
  followingUserList: `${user}/followingUserList`,
  requestList: `${user}/requestList`,
  acceptOrDenyRequest: `${user}/acceptOrDenyRequest`,
  getSubscription: `${user}/getSubscription`,
  checkPlan: `${user}/checkPlan`,

  clearNotification: `${user}/clearNotification`,
  resetPassword: `${user}/resetPassword`,
  logOut: `${user}/logOut`,
  interestedClientList: `${user}/interestedClientList`,
  listSearchUser: `${user}/listSearchUser`,
  othersProfile: `${user}/othersProfile`,
  setDefaultUserLanguage: `${user}/setDefaultUserLanguage`,
  deactivateUserAccount: `${user}/deactivateUserAccount`,
  interestedClientList: `${user}/interestedClientList`,
  getCurrencyOne: `${user}/getCurrencyOne`,
  listUserAppointment: `${user}/listUserAppointment`,
  viewAppointment: `${user}/viewAppointment`,
  markasDoneAppointment: `${user}/markasDoneAppointment`,
  listfeedbacknrating: `${user}/listfeedbacknrating`,
  listBlockDoctorUser: `${user}/listBlockDoctorUser`,
  addBlockDoctorUser: `${user}/addBlockDoctorUser`,
  removeBlockDoctorUser: `${user}/removeBlockDoctorUser`,
  canceleAppointment: `${user}/canceleAppointment`,
  createCheckoutSession: `${user}/create-checkout-session`,
  listSubscription: `${admin}/listSubscription`,
  // product
  listProduct: `${product}/myProductList`,
  addProduct: `${product}/addProduct`,
  viewProduct: `${product}/viewProduct`,
  updateProduct: `${product}/updateProduct`,
  deleteProduct: `${product}/deleteProduct`,

  // service
  addService: `${service}/addService`,
  listService: `${service}/myServiceList`,
  updateService: `${service}/updateService`,
  deleteService: `${service}/deleteService`,
  // sub-category
  listSubCategory: `${category}/listSubCategory`,
  // category
  listCategory: `${category}/listCategory`,
  // event
  addEvent: `${event}/addEvent`,
  viewEvent: `${event}/viewEvent`,
  deleteEvent: `${event}/deleteEvent`,
  myListEvent: `${event}/myListEvent`,
  listEvent: `${event}/listEvent`,
  // pet
  listPet: `${pet}/myPetList`,
  viewPet: `${pet}/viewPet`,
  addPetToMarketPlace: `${pet}/addPetToMarketPlace`,
  removePet: `${pet}/removePet`,
  removePetToMarketPlace: `${pet}/removePetToMarketPlace`,
  languageList: `${admin}/languageList`,
  viewStaticContent: `${statics}/static`,

  pet: `${pet}/pet`,
  updatepet: `${pet}/updatepet`,
  petBreedList: `${pet}/petBreedList`,
  petCategoryList: `${pet}/petCategoryList`,
  addPetToMarketPlace: `${pet}/addPetToMarketPlace`,
  removePetToMarketPlace: `${pet}/removePetToMarketPlace`,
  removePet: `${pet}/removePet`,
  // upload
  uploadMultipleFile: `${post}/uploadMultipleFile`,
  // socket
  oneToOneChatApi: `${socket}/oneToOneChatApi`,
  chatHistory: `${socket}/chatHistory`,
  deleteChatHistory: `${socket}/deleteChatHistory`,
  readChat: `${socket}/readChat/`,
};
export const socketConfig = {
  connectSocket: `connect`,
  getOnline: `onlineUser`,
  getOffline: `offlineUser`,
  checkOnline: `checkOnlineUser`,
  StartChat: `oneToOneChat`,
  chatHistory: `chatHistory`,
  chatList: `chatList`,
  disconnect: `disconnect`,
};

export default Apiconfigs;
