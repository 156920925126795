import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import Apiconfigs from "src/apiConfig/config";
import { socketNotification } from "src/apiConfig/config";
import { calculateTimeLeft } from "src/utils/index";
import { useSelector } from "react-redux";
export const AuthContext = createContext();

const setSession = (accessToken) => {
  if (accessToken) {
    sessionStorage.setItem("token", accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    sessionStorage.removeItem("token");
    delete axios.defaults.headers.common.Authorization;
  }
};

function checkLogin() {
  const accessToken = window.sessionStorage.getItem("token");

  return accessToken ? true : false;
}
export default function AuthProvider(props) {
  const pathnameValue = window?.location?.pathname.slice(1);
  const [isLogin, setIsLogin] = useState(checkLogin());
  const [userData, setUserData] = useState({});
  const [remainingEvent, setRemainingEvent] = useState(0);
  const [remainingMissedPet, setRemainingMissedPet] = useState(0);
  const [remainingPetCount, setRemainingPetCount] = useState(0);
  const [remainingServiceCount, setRemainingServiceCount] = useState(0);
  const [remainingProductCount, setRemainingProductCount] = useState(0);
  const [checkSubscription, setCheckSubscription] = useState(true);
  const [endTime, setEndtime] = useState();
  const [timeLeft, setTimeLeft] = useState();
  const [category, setCategory] = useState([]);
  const [_pettypecategory, setPetTypeCategory] = useState([]);
  const fireToken = useSelector((state) => state.auth.firebaseToken);
  const [subCategory, setSubCategory] = useState([]);
  const [selectedId, setSelectedId] = useState("");
  const [categoryType, setcategoryType] = useState("");
  const [notificationList, setNotificationList] = useState([]);
  const [unreadChats, setUnreadChats] = useState(0);
  const [chatMessageData, setChatMessageData] = useState([]);
  const [isSocketOn, setisSocketOn] = useState(false);
  const [_currencyCode, setCurrencyCode] = useState("");
  const [_ipaddress, setIpAddress] = useState("");

  const GetIpAddress = async () => {
    try {
      const res = await axios({
        url: "https://api.ipify.org/",
        method: "GET",
      });
      if (res) {
        setIpAddress(res?.data);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const GetCurrencyCode = async () => {
    try {
      const res = await axios({
        url: Apiconfigs?.getCurrencyOne,
        method: "GET",
        params: {
          ip: _ipaddress,
        },
      });
      if (res) {
        setCurrencyCode(res?.data?.result?.currency);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    GetIpAddress();
    if (_ipaddress !== "") {
      GetCurrencyCode();
    }
  }, [_ipaddress]);

  useEffect(() => {
    const web = new WebSocket(socketNotification);
    try {
      let socketData = {
        token: window.sessionStorage.getItem("token"),
        requestType: "NotificationList",
      };
      web.onopen = () => {
        web.send(JSON.stringify(socketData));
        web.onmessage = async (event) => {
          if (event.data !== "[object Promise]" && event.data !== "null") {
            let obj = JSON.parse(event.data);
            setisSocketOn(true);
            if (obj.result && obj.result.length > 0) {
              setNotificationList(obj.result);
            }
          } else {
            setNotificationList([]);
          }
        };
      };
    } catch (err) { }
    return () => {
      web.close();
    };
  }, [window.sessionStorage.getItem("token")]);
  const getUserProfileDatahandler = async (token) => {
    try {
      const res = await axios({
        method: "GET",
        url: Apiconfigs.profile,
        headers: {
          token: token,
        },
      });

      if (res.data.responseCode === 200) {
        setUserData(res?.data?.result);
        handleFetchPlans();
        handleSubscription();
      }
    } catch (error) {
      console.log(error);
      if (error.response) {
        if (error.response.data.responseCode === "404") {
          window.sessionStorage.removeItem("token");
        }
      }
    }
  };

  const handleFetchPlans = async () => {
    try {
      const data = {};
      const response = await axios({
        method: "POST",
        url: Apiconfigs.checkPlan,
        headers: {
          token: window.sessionStorage.getItem("token"),
        },
        data: data,
      });
      if (
        response?.data !== undefined &&
        response?.data?.responseCode !== undefined
      ) {
        if (response.data.responseCode === 200) {
          setRemainingEvent(response.data.result.remainingEvent)
          setRemainingMissedPet(response.data.result.remainingMissedPet)
          setRemainingPetCount(response.data.result.remainingPetCount)
          setRemainingServiceCount(response.data.result.remainingServiceCount)
          setRemainingProductCount(response.data.result.remainingProductCount)
        } else if (response) {
        }
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubscription = async () => {
    try {
      const data = {};

      const response = await axios({
        method: "POST",
        url: Apiconfigs.getSubscription,
        headers: {
          token: window.sessionStorage.getItem("token"),
        },
        data: data,
      });
      if (response.data.responseCode === 407) {
        setCheckSubscription(false);
        if (window.sessionStorage.getItem("handleReload") !== null && window.sessionStorage.getItem("handleReload") !== undefined) {
          window.sessionStorage.setItem("handleReload", true)
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (endTime) {
      const timer = setTimeout(() => {
        setTimeLeft(calculateTimeLeft(endTime * 1000));
      }, 5000);
      return () => clearTimeout(timer);
    }
  });
  useEffect(() => {
    if (window.sessionStorage.getItem("token")) {
      getUserProfileDatahandler(window.sessionStorage.getItem("token"));
    }
  }, []);
  const listCategoryHandler = async () => {
    try {
      const res = await axios({
        method: "GET",
        url: Apiconfigs.listCategory,
        params: {
          type: categoryType,
        },
      });
      if (res) {
        setCategory(res.data.result.docs);
      }
    } catch (error) { }
  };
  const listPetTypeCategoryHandler = async (token) => {
    try {
      const res = await axios({
        method: "POST",
        url: Apiconfigs.petCategoryList,
        headers: {
          token: window.sessionStorage.getItem("token"),
        },
        params: {
          page: 1,
          limit: 10,
        },
      });
      if (res) {
        setPetTypeCategory(res?.data?.result);
      }
    } catch (error) { }
  };
  useEffect(() => {
    listPetTypeCategoryHandler();
  }, []);
  const listSubCategoryHandler = async (selectedId) => {
    try {
      const res = await axios({
        method: "GET",
        url: Apiconfigs.listSubCategory,
        params: {
          categoryId: selectedId,
        },
      });
      if (res) {
        setSubCategory(res.data.result.docs);
      }
    } catch (error) { }
  };
  useEffect(() => {
    if (selectedId) {
      listSubCategoryHandler(selectedId);
    }
  }, [selectedId]);
  useEffect(() => {
    if (categoryType !== "") {
      listCategoryHandler();
    }
  }, [categoryType]);
  //CHAT COUNT
  useEffect(() => {
    const web = new WebSocket(socketNotification);
    const accessToken = sessionStorage.getItem("token");
    if (accessToken) {
      try {
        web.onopen = () => {
          const dataToSend = {
            user_token: accessToken,
          };
          web.send(JSON.stringify(dataToSend));
          web.onmessage = async (event) => {
            if (event.data !== "[object Promise]" && event.data !== "null") {
              let obj = JSON.parse(event.data);
              setUnreadChats(obj);
            }
          };
        };
        return () => {
          web.close();
          setUnreadChats(0);
        };
      } catch (err) {
        setUnreadChats(0);
      }
    }
  }, [userData]);
  //CHAT ChatHistory
  useEffect(() => {
    const web = new WebSocket(socketNotification);

    const accessToken = sessionStorage.getItem("token");

    if (accessToken && userData && userData._id) {
      try {
        web.onopen = () => {
          const dataToSend = {
            type: "ChatHistory",
            senderId: userData._id,
          };
          web.send(JSON.stringify(dataToSend));
          web.onmessage = async (event) => {
            if (event.data !== "[object Promise]" && event.data !== "null") {
              let obj = JSON.parse(event.data);
              setChatMessageData(obj.result);
            }
          };
        };
        return () => {
          web.close();
          setChatMessageData();
        };
      } catch (err) {
        setChatMessageData();
      }
    }
  }, [userData]);
  const logOutHandler = async () => {
    try {
      const res = await axios({
        method: "GET",
        url: Apiconfigs.logOut,
        headers: {
          token: sessionStorage.getItem("token"),
        },
        params: {
          fireToken: fireToken,
        },
      });
    } catch (error) { }
  };

  let data = {
    userLoggedIn: isLogin,
    userData,
    category,
    _pettypecategory,
    subCategory,
    timeLeft,
    remainingEvent,
    remainingMissedPet,
    remainingPetCount,
    remainingServiceCount,
    remainingProductCount,
    checkSubscription,
    setTimeLeft,
    setEndtime,
    setSelectedId,
    setcategoryType,
    notificationList,
    chatMessageData,
    unreadChats,
    _currencyCode,
    // _listappointment,
    // _listappointment_telehealth,
    setIsLogin: (data) => setIsLogin(data),
    logOutHandler: (data) => logOutHandler(data),

    checkLogin: (data) => checkLogin(data),
    getUserProfileDatahandler: (data) => getUserProfileDatahandler(data),
    userLogIn: (type, data) => {
      setSession(data);
      setIsLogin(type);
    },
  };

  return (
    <AuthContext.Provider value={data}>{props.children}</AuthContext.Provider>
  );
}
