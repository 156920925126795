import React, { useContext } from "react";
import { Redirect } from "react-router-dom";
import { AuthContext } from "src/context/Auth";

export default function AuthGuard(props) {
  const { children } = props;
  const auth = useContext(AuthContext);
  const pathnameValue = window?.location?.pathname.slice(1);
  if (!auth.userLoggedIn) {
    return <Redirect to="/" />;
  }
  if (auth.checkSubscription === false && pathnameValue !== "plans") {
    return <Redirect to="/plans?subscribe" />;
  }
  return <>{children}</>;
}
